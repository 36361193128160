<template>
  <div ref="paypal"></div>
</template>

<script>
export default {
  data () {
    return {
      order: {
        description: 'Buy en pEsos',
        amount: {
          currency_code: 'MXN',
          value: 20
        }
      }
    }
  },
  mounted: function () {
    const script = document.createElement('script')
    const ClientID = 'AUHx_ZSy24juvXG0OIcitfDpJYyzzqC9B8LX5SRxjOUlmikDtyQJ6nDvkUfOXRol1Qs4OyK1KK35sHEl'
    script.src = `https://www.paypal.com/sdk/js?client-id=${ClientID}`
    script.addEventListener('load', this.setLoaded)
    document.body.appendChild(script)
  },

  methods: {
    setLoaded: function () {
      window.paypal
        .Buttons({
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [this.order]
            })
          },
          onApprove: async (data, actions) => {
            const order = await actions.order.capture()
            // ajax request
          },
          onError: err => {
            console.log(err)
          }
        })
        .render(this.$refs.paypal)
    }
  }
}
</script>
